<template>
  <v-container grid-list-xl fluid class="pa-0 mt-0">
    <v-card class="mx-auto my-3" max-width="1520" outlined>
      <v-progress-linear
        v-if="showLoading == true"
        indeterminate
        color="primaryTrack"
      ></v-progress-linear>
      <v-row no-gutters>
        <v-text-field
          v-model="search"
          append-icon="ph-magnifying-glass"
          label="Pesquisar"
          single-line
          hide-details
          class="text-center pa-2 my-4 mx-3"
          color="accentTrack"
        ></v-text-field>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              v-bind="attrs"
              v-on="on"
              small
              class="my-7 mx-5"
              color="accentTrack"
              dark
              @click="getStatus()"
            >
              Atualizar
              <v-icon dark right>ph-arrows-clockwise</v-icon>
            </v-btn>
          </template>
          <span>
            Aqui você sincroniza seus usuários do
            <strong>Google Workspace</strong>
          </span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              v-bind="attrs"
              v-on="on"
              small
              class="my-7 mx-5"
              color="accentTrack"
              dark
              @click="showConfigOnboarding = true"
            >
              Configurações
              <v-icon dark right>ph-gear</v-icon>
            </v-btn>
          </template>
          <span> Aqui você verifica as configurações do Conecta Track </span>
        </v-tooltip>

        <v-dialog v-model="showConfigOnboarding" max-width="600">
          <v-card class="pt-3">
            <v-card-title> Configurações Conecta Track </v-card-title>

            <v-card-text>
              Para que o Conectra Track funcione por completo, você deve
              instalar a extensão no navegador Google Chrome, e ativar para
              todos os seus usuários do Google Workspace.
            </v-card-text>

            <v-card-text>
              Após a instalação, você deve orientar os usuários a entrarem na
              extensão com suas contas do Google Workspace.
            </v-card-text>

            <v-card-text>
              Para acessar o link da extensão no Google Chrome
              <a href="https://tinyurl.com/yxdr47to" target="_blank"
                >clique aqui</a
              >
            </v-card-text>

            <v-card-text>
              Para acessar o painel do Google Workspace, e configurar a extensão
              para todos da empresa
              <a
                href="https://admin.google.com/ac/chrome/apps/user"
                target="_blank"
                >clique aqui</a
              >
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row
        v-if="showLoading == false"
        class="mx-5 mb-2 text-caption"
        justify="end"
        >Atualizado em {{ formatDateAndHour(now) }}</v-row
      >
      <v-data-table
        class="row_pointer mb-12"
        :headers="headers"
        :items="printItems"
        :search="search"
        :sort-by="['name']"
        :sort-desc="[false]"
        :footer-props="{ itemsPerPageOptions: itemsOptions }"
        single-select
        v-if="showLoading == false"
      >
        <template v-slot:[`item.name`]="{ item }">
          <router-link
            :to="{ path: '/track/user', query: { id: item.id_google } }"
          >
            <v-row>
              <div class="pa-2">
                <v-avatar size="40">
                  <img
                    v-if="item.photo == '' || item.photo == null"
                    src="@/assets/user-default.png"
                  />
                  <img v-else :src="item.photo" />
                </v-avatar>
                <span class="ma-5">{{ item.name }}</span>
              </div>
            </v-row>
          </router-link>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <label :class="item.status" class="status" v-on="on">{{
                item.status
              }}</label>
            </template>
            <span v-if="item.status == 'Online'"
              >{{ item.name }} interagiu com uma URL nos últimos 5 minutos</span
            >

            <span v-if="item.status == 'Offline'"
              >{{ item.name }} não interagiu com uma URL à mais de 5
              minutos</span
            >

            <span v-if="item.status == 'Desativado'"
              >{{ item.name }} não está usando a extensão Conecta Track</span
            >
          </v-tooltip>
        </template>

        <template v-slot:[`item.lastacess`]="{ item }">
          <label v-if="item.lastacess != ''">{{
            formatDateAndHour(item.lastacess, true)
          }}</label>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import {
  getUsersByLocalStorage,
  getUsersByRequest,
  getUsersStatus,
} from "@/helpers/services/getUsers";
import { formatDateAndHour } from "@/helpers/services/utils";
import * as moment from "moment";

export default {
  name: "TrackPageUsers",
  data() {
    return {
      hidden: false,
      gif_track: require("@/assets/images/gif_track.gif"),
      breadcrumbs: [
        {
          text: "Início",
          disabled: false,
          to: "/",
          color: "secondary",
        },
        {
          text: "Conecta Track",
          disabled: false,
          to: "",
          color: "secondary",
        },
      ],
      showLoading: false,
      showConfigOnboarding: false,
      model: 1,
      itemsOptions: [20, 50, 200],
      search: "",
      headers: [
        { text: "Usuários", align: "start", filterable: true, value: "name" },
        { text: "E-mail", value: "email" },
        { text: "Status", width: 85, value: "status" },
        { text: "Última atividade", value: "lastacess" },
      ],
      items: [],
      printItems: [],
      now: "",
    };
  },

  beforeMount() {
    this.getUsers();
    this.now = moment();
  },

  methods: {
    formatDateAndHour,
    getUsersByLocalStorage,
    getUsersByRequest,

    async getStatus() {
      this.showLoading = true;
      this.printItems = [];
      let data = await getUsersStatus();
      if (data == "error") {
        this.items.map((c) =>
          this.printItems.push({
            name: c.name,
            email: c.email,
            status: "",
            last_login: c.last_login,
            photo: c.photo,
            id_google: c.id_google,
          })
        );
      } else {
        this.updateUserStatus(data);
      }
      this.showLoading = false;
    },

    updateUserStatus(data) {
      this.items.map((user) => {
        let aux = data.find((d) => d.id_google == user.id_google);
        if (aux) {
          let info = {
            name: user.name,
            email: user.email,
            status: aux.status == "true" ? "Online" : "Offline",
            lastacess: aux.last_date,
            photo: user.photo,
            id_google: user.id_google,
          };
          this.printItems.push(info);
        } else {
          this.printItems.push({
            name: user.name,
            email: user.email,
            status: "Desativado",
            lastacess: "",
            photo: user.photo,
            id_google: user.id_google,
          });
        }
        aux = "";
      });
      this.showLoading = false;
    },

    async getUsers() {
      this.showLoading = true;

      if (this.getUsersByLocalStorage() == false) {
        this.items = await getUsersByRequest();
        this.showLoading = false;
      } else {
        this.items = getUsersByLocalStorage();
        this.showLoading = false;
      }

      this.getStatus();
    },

    syncUser() {
      let _this = this;
      this.showLoading = true;

      const token = localStorage.getItem("token");
      this.$axios
        .get(process.env.VUE_APP_API_BASE_URL + "/users/sync", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          _this.items = response.data;
          localStorage.setItem("users", JSON.stringify(_this.items));
          _this.showLoading = false;
        })
        .catch((error) => {
          console.error(error);
          _this.showLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.row_pointer {
  cursor: pointer;
}
.Desativado {
  color: gray;
}
.Offline {
  color: red;
}
.Online {
  color: green;
}
.status {
  font-weight: bold;
}
a {
  text-decoration: none;
}
</style>
